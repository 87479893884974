
import ExpenseInput from "@/components/ExpenseInput.vue";
import Toolbar from "@/components/Toolbar.vue";
import { defineComponent, inject, ref } from "vue";
import { takePhoto } from "@/composables/getFile";
import { camera, close, folder } from "ionicons/icons";
import { agent } from "@/agent";
import { useStorage } from "@/composables/useStorage";
import { useFeature } from "@/composables/useFeature";
import { useRouter } from "vue-router";
import { organizations } from "@/constants";

import { format } from "date-fns-tz";

import {
  IonRow,
  IonCol,
  IonPage,
  IonHeader,
  IonContent,
  IonModal,
  onIonViewDidEnter
} from "@ionic/vue";

import toolbar from "@/components/Toolbar.vue";
import ZoomableImg from "@/components/ZoomableImg.vue";

export default defineComponent({
  name: "New",
  components: {
    IonRow,
    IonCol,
    IonHeader,
    IonContent,
    IonModal,
    IonPage,
    toolbar,
    ZoomableImg,
    ExpenseInput,
    Toolbar
  },
  setup() {
    const expenseInput = ref();
    const isMobile = inject("isMobile") as boolean;
    const user = ref();
    const { getAllCategories } = agent();
    const categories = ref([]);

    const { create } = agent();
    const { getPreferences, getUser } = useStorage();
    const { loading, raiseToast } = useFeature();
    const router = useRouter();

    onIonViewDidEnter(async () => {
      user.value = await getUser();

      const res = await getAllCategories();
      if (res && res.data) {
        categories.value = res.data.sort((a: any, b: any) =>
          a.name.localeCompare(b.name)
        );
      }

      if (
        isMobile &&
        router.options.history.state.back === "/pin" &&
        (await getPreferences()).openCameraOnLaunch
      ) {
        takePhoto();
      }
    });

    const submit = async (expense: any) => {
      const loadingOverlay = await loading("Please wait. Creating expense");
      loadingOverlay.present();

      try {
        await create(expense);
        expenseInput.value.resetForm();

        await raiseToast("Expense created", "success");
      } catch (e) {
        if (e instanceof Error) {
          await raiseToast(e.message, "danger");
          return;
        } else {
          await raiseToast("An unknown error occured", "danger");
          return;
        }
      } finally {
        loadingOverlay.dismiss();
      }
    };

    return {
      camera,
      categories,
      close,
      expenseInput,
      folder,
      isMobile,
      organizations,
      user,
      format,
      takePhoto,
      submit
    };
  }
});
