import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toolbar = _resolveComponent("toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ExpenseInput = _resolveComponent("ExpenseInput")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { class: "ion-no-border" }, {
        default: _withCtx(() => [
          _createVNode(_component_toolbar, { title: "New" })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, {
                "size-md": "6",
                "offset-md": "3",
                class: "ion-padding"
              }, {
                default: _withCtx(() => [
                  (_ctx.user)
                    ? (_openBlock(), _createBlock(_component_ExpenseInput, {
                        key: 0,
                        ref: "expenseInput",
                        categories: _ctx.categories,
                        organizations: _ctx.organizations,
                        user: _ctx.user,
                        onSubmit: _ctx.submit
                      }, null, 8, ["categories", "organizations", "user", "onSubmit"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}